

// Refresh content of elements with data-refresh attribute
window.refreshAll = function(selector) {
  selector = selector || '[data-refresh]';
  $(selector).each(function() {
    const el  = $(this);
    const url = el.data('refresh');
    $.get(url, (html) => {
      el.replaceWith(html);
    });
  });

};