
$(() => {
  // auto-toggle
  window.initAutoToggle = (container) => {
    $('.auto-toggle', container).each(function() {
      const el = $(this);
      const input = $(el.data('input'));
      let values = el.data('value');
      let not = el.data('not');
      if (values && values.indexOf && values.indexOf(',') > -1) {
        values = values.split(',');
      } else {
        values = [ String(values) ];
      }
      function autotoggle(e) {
        e?.preventDefault();
        let val = input.val();
        if (input.attr('type') === 'checkbox') {
          val = input.is(':checked');
        } else if (input.attr('type') === 'radio') {
          val = $(el.data('input') + ':checked').val();
        }
        const visible = not ? values.indexOf(val) < 0 : values.indexOf(val) > -1;
        el.toggle(visible);
        el.find('input, select, textarea').not('.tom-select').prop('disabled', !visible);
      }
      input.on('change', autotoggle);
      autotoggle();
    });
  };

  window.initAutoToggle('body');
});