$(() => {
  $('input[data-component="daterangepicker"]').each(function() {
    $(this).daterangepicker({
      // startDate: moment().subtract(1, 'month'),
      // endDate: moment(),
      minDate: $(this).attr('minDate'),
      maxDate: $(this).attr('maxDate'),
      autoUpdateInput: false,
      showDropdowns: true,
      opens: 'right',
      locale: {
        format: 'DD/MM/YYYY',
        cancelLabel: 'Annuler',
        applyLabel: 'Appliquer',
        fromLabel: 'De',
        toLabel: 'à',
        customRangeLabel: 'Personnalisé',
        daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      }
    });

    // Update input value on daterangepicker apply
    $(this).on('apply.daterangepicker', function(_, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));

      // Submit auto form
      if ($(this).closest('form').hasClass('auto-submit')) {
        $(this).closest('form').trigger('submit');
      }
    });

    // Reset input value on cancel
    $(this).on('cancel.daterangepicker', function() {
      $(this).val('');

      // Submit auto form
      if ($(this).closest('form').hasClass('auto-submit')) {
        $(this).closest('form').trigger('submit');
      }
    });
  });
});