Alpine.data('alpineModal', () => {
  return {
    isModalOpen: false,
    modalContent: '',
    openModal(el) {
      const href = el.getAttribute('href');
      fetch(href)
      .then(response => {
        if (response.ok) {
          this.isModalOpen = true;
          this.modalContent = response.text();
        }
      });
    },
    submit(form) {
      const formData = new FormData(form);

      fetch(form.action, {
        method: form.method ?? 'POST',
        body: formData
      })
      .then(response => {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          return response.json();
        }
        return response.text();
      })
      .then(data => {
        if (data.refresh) {
          window.location.reload();
        } else {
          this.modalContent = data;
        }
      });
    }
  };
});
