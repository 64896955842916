$(() => {

  $(document).on('click', '[data-clipboard-copy]', function() {
    // Prevent multiple clicks
    if ($(this).attr('data-clipboard-copied')) return;

    const textToCopy = $(this).attr('data-clipboard-copy');
    navigator.clipboard.writeText(textToCopy);
    $(this).attr('data-clipboard-copied', true);

    // Add notification
    const message = $(this).attr('data-clipboard-copy-message') ?? 'Copié dans le presse-papiers';
    const notification = $(`<div aria-live="assertive" class="z-10 pointer-events-none fixed inset-0 flex items-start px-4 py-6 sm:p-6">
      <div class="flex w-full flex-col items-end space-y-4">
        <div class="pointer-events-auto overflow-hidden w-72 rounded-lg shadow-lg ring-1 bg-green-50 ring-green-100">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <i class="fal fa-circle-check text-green-500"></i>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">${message}</p>
              </div>
              <div class="ml-4 flex flex-shrink-0" id="button-container">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>`);
    const closeNotification = () => {
      notification?.remove();
      $(this).removeAttr('data-clipboard-copied', true);
    };
    const closeButton = $(
      `<button type="button" class="btn-close inline-flex rounded-md text-gray-400 hover:text-gray-500">
        <span class="sr-only">Fermer</span>
        <i class="fal fa-xmark"></i>
      </button>`
    ).on('click', closeNotification);

    notification.find('#button-container').append(closeButton);
    $(notification).insertAfter(this);

    setTimeout(closeNotification, 4000);
  });

});