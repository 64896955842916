

$(() => {

  $('.document-upload').each(function() {
    
    const upload    = $(this);
    const dropzone  = upload.find('.drop-zone');
    const input     = upload.find('input[type="file"]');

    // Afficher le dialogue de sélection de fichier lorsque la zone est cliquée
    dropzone.on('click', () => {
      input.trigger('click');
    });

    // Empêcher le comportement par défaut pour les événements drag & drop
    dropzone.on('dragover', (e) => {
      e.preventDefault();
      dropzone.css('background-color', '#fcf0f0');
    });

    dropzone.on('dragleave', (e) => {
      e.preventDefault();
      dropzone.css('background-color', '');
    });

    // Gérer le dépôt des fichiers
    dropzone.on('drop', (e) => {
      e.preventDefault();
      dropzone.css('background-color', '');
      const files = e.originalEvent.dataTransfer.files;
      handleFiles(files);
    });

    // Gérer les fichiers sélectionnés via le dialogue de sélection
    input.on('change', () => {
      const files = input[0].files;
      handleFiles(files);
    });

    // 
    const handleFiles = (files) => {
      
      const data = new FormData();
      data.append('type_id',  upload.data('type_id') || $('select[name="type_id"]').val());
      data.append('edit',     upload.data('edit'));
      data.append('url',      upload.data('url'));
      data.append('active',   upload.data('active'));
      for (const file of files) {
        data.append('documents', file);
      }
      
      const txt = dropzone.text();
      dropzone.text(' Envoi des fichiers en cours. Merci de bien vouloir patienter.');
      $.ajax({
        url: upload.data('action'),
        type: 'POST',
        data,
        processData: false,
        contentType: false,
        success: function(html) {
          upload.find('.upload-content').html(html);
          dropzone.text(txt);
          window.refreshAll();
        },
        error: function(xhr, status, error) {
          // TODO: handle error
          dropzone.text(error);
        }
      });
    };
    
  });

});