

window.blockHandler = (el) => {
  return {
    result: '',
    fetchData(url) {
      fetch(url)
      .then(response => {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          return response.json();
        }
        return response.text();
      })
      .then(data => {
        if (data.err) {
          window.location.reload();
        } else {
          this.result = data;
        }
      }).then(() => {
        window.initAutoToggle(el);
        window.initRefreshCities(el);
        window.initTomSelect(el);
        $('select[name="pmsp_study_status"]').trigger('change');
      });
    },
    submitForm(url) {
      const form = el.querySelector('form');
      const formData = new FormData(form);

      fetch(url, {
        method: 'POST',
        body: formData
      })
      .then(response => {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          return response.json();
        }
        return response.text();
      })
      .then(data => {
        if (data.refresh) {
          window.location.reload();
        } else {
          this.result = data;
        }
      }).then(() => {
        window.initAutoToggle(el);
        window.initRefreshCities(el);
        window.initTomSelect(el);
        $('select[name="pmsp_study_status"]').trigger('change');
      });
    }
  };
};
