
$(() => {

  if (!document.getElementById('mentions-input')) {
    return;
  }

  // 
  var tribute = new Tribute({
    allowSpaces: true,
    lookup: 'name',
    noMatchTemplate: () => '',
    values: searchUsers,
    containerClass: 'bg-white shadow pt-2 px-2 rounded-lg',
    menuItemTemplate: function (item) {
      return `<div class="mb-2"><i class="fal fa-user-circle text-gray-400 me-1"></i> ${item.string}</div>`;
    },
    selectTemplate: function(item) {
      if (typeof item === 'undefined') return null;
      if (this.range.isContentEditable(this.current.element)) {
        return (
          `<span contenteditable="false"><a class="text-ladom-blue" data-value="${item.original.id}">` +
          item.original.name +
          '</a></span>'
        );
      }
      return '@' + item.original.value;
    },
    // requireLeadingSpace: false
  });

  tribute.attach(document.getElementById('mentions-input'));

  // search users
  function searchUsers(name, callback) {
    if (name.length < 2) {
      return callback([]);
    }

    $.ajax({
      type: 'GET',
      url:  '/users',
      data: {name},
      success: (res) => {
        callback(res.users);
      }
    });
  }

  // adding the mention id to the form
  document.getElementById('mentions-input')
  .addEventListener('tribute-replaced', function(e) {
    $('<input>').attr({
      type: 'hidden',
      name: 'mentions_ids',
      value: e.detail.item.original.id
    }).appendTo($('#notes-form'));
  });

  // adding the body to the form
  $('#notes-form').on('submit', function() {
    $('<input>').attr({
      type: 'hidden',
      name: 'text',
      value: $('#mentions-input').html()
    }).appendTo($('#notes-form'));
    return true;
  });

});
