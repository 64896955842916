
window.beneficiaryFormHandler = () => {
  return {
    hasNewBeneficiary: false,
    init() {
      const initialToggle = this.$el.dataset.initialToggle;
      this.hasNewBeneficiary = Boolean(initialToggle);

      /* Toggle submit State */
      this.$watch('hasNewBeneficiary', value => {
        /* Get form submit button, and not the submit button for adding a beneficiary */
        const submitButton = document.querySelector('button[type="submit"]:not([formaction])');
        if (!submitButton) return;

        if (value) {
          submitButton.setAttribute('disabled', true);
          submitButton.setAttribute('data-tooltip', 'Veuillez confirmer l\'ajout du nouveau bénéficiaire ou annuler');
        } else {
          submitButton.removeAttribute('disabled');
          submitButton.removeAttribute('data-tooltip');
        }
      });
    }
  };
};