

$(() => {

  $(document).on('click', '.one-click', function(e) {
    const el = $(this);
    el.addClass('disabled');
    setTimeout(() => {
      el.removeClass('disabled');
    }, 2000);
  });

  $(document).on('click', '.disabled', function(e) {
    e.preventDefault();
  });
});

