
$(() => {

  $(document).on('click', '.select-search li', function() {
    const $li         = $(this);
    const $container  = $li.parents('.select-search');
    const $input      = $container.find('input[type="hidden"]');
    const $showInput  = $container.find('input[type="text"]');

    if ($li.data('formaCode')) {
      // Certifinfo
      $('#certifinfo_id').val($li.data('value'));
      $('#certifinfo_formacode').val($li.data('formaCode'));
      $('#certifinfo_rncp').val($li.data('rncp'));
      $('#certifinfo_certification').val($li.data('certification'));
      $showInput.val($li.text());
    } else if ($li.data('nda')) {
      // Training center
      $('#formation_training_center_nda').val($li.data('nda'));
      $('#formation_training_center_name').val($li.data('name'));
      $('#formation_training_center_address_lpof').val($li.data('address'));
      $('#formation_training_center_zipcode').val($li.data('zipcode'));
      $('#formation_training_center_city').val($li.data('city'));
      $('#formation_training_center_address1').val(''); // reset alternative address
      $showInput.val($li.data('value')); // store siret
    } else {
      $showInput.val($li.text());
    }

    $input.val($li.data('value'));
    $input.trigger('change');
    $showInput.prop('readonly',true);
    $container.find('ul').hide();
    $container.find('.clear').show();
    $container.find('.list').hide();
  });

  $(document).on('click', '.select-search .clear', function() {
    const $container  = $(this).parents('.select-search');
    const $input      = $container.find('input[type="hidden"]');
    const $showInput  = $container.find('input[type="text"]');

    $input.val('');
    $('#certifinfo_id').val('');
    $('#certifinfo_formacode').val('');
    $('#certifinfo_rncp').val('');
    $('#certifinfo_certification').val('');
    $('formation_training_center_nda').val('');
    $('formation_training_center_name').val('');
    $('formation_training_center_address_lpof').val('');
    $('formation_training_center_address1').val('');
    $('formation_training_center_zipcode').val('');
    $('formation_training_center_city').val('');

    $input.trigger('change');
    $showInput.val('');
    $showInput.prop('readonly',false);
    $(this).hide();
    $container.find('.list').show();
  });

  function searchQuery() {
    const $container  = $(this).parents('.select-search');
    const $ul         = $container.find('ul');
    const url = $container.data('url');
    if ($(this).val().length < 3) {
      $ul.hide();
      return ;
    }

    const $spinner = $container.find('.spinner');
    $spinner.show();

    $.get(url + '?search=' + $(this).val(), (data) => {
      $ul.empty();
      if (data.results) {
        $ul.show();
        $spinner.hide();
        for (const result of data.results) {
          let $li = $('<li>');

          if (data.type === 'certifinfo') {
            $li.html(`${result.name}   <span class="text-gray-500">(${result.rncp})</span>`);
            $li.data('value', result.id);
            $li.data('rncp', result.rncp);
            $li.data('formaCode', result.formaCode);
            $li.data('certification', result.certification);
          } if (data.type === 'training-center') {
            $li.html(`${result.name}  -  <span class="text-gray-500 text-sm"> ${result.siret}</span>`);
            $li.data('value', result.siret);
            $li.data('nda', result.nda);
            $li.data('name', result.name);
            $li.data('address', result.address);
            $li.data('zipcode', result.zipcode);
            $li.data('city', result.city);
          } else {
            $li.html(result.name);
          }

          $li.result = result;
          $li.addClass('relative cursor-pointer select-none py-2 pl-3 pr-6 text-gray-900 hover:bg-gray-100');
          $ul.append($li);
        }
      }
    });
  }

  $(document).on('keyup', '.select-search input[type="text"]', debounce(searchQuery, 300));
  $(document).on('focus', '.select-search input[type="text"]', searchQuery);
});
