

$(() => {

  $(document).on('change', 'select[name="pmsp_study_status"]', function() {
    const value = $(this).val();

    $('select[name="pmsp_study_level"').empty();
    $('select[name="pmsp_sector"').empty();
    if (value === 'Etudiant') {
      ['Bac +1', 'Bac +2', 'Bac +3', 'Bac +4', 'Bac +5'].forEach((level) => {
        const option = $('<option></option>').attr('value', level).text(level);
        $('select[name="pmsp_study_level"').append(option);
      });

      ['Section de technicien supérieur', 'Institut universitaire de technologie', 'Licence professionnelle', 'Master'].forEach((sector) => {
        const option = $('<option></option>').attr('value', sector).text(sector);
        $('select[name="pmsp_sector"').append(option);
      });

    } else {
      const option = $('<option></option>').attr('value', 'Baccalauréat').text('Baccalauréat');
      $('select[name="pmsp_study_level"').append(option);

      ['Professionnel', 'Technologique'].forEach((sector) => {
        const option = $('<option></option>').attr('value', sector).text(sector);
        $('select[name="pmsp_sector"').append(option);
      });
    }
  });

});
